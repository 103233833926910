<template>
	<div class="body">
		<div class="top-search">
			<Button type="primary" @click="add_version">添加版本</Button>
		</div>
		<Table :data="data" :columns="columns" style="margin-top: 20px;" :loading="loading">
			<template slot="status" slot-scope="{row}">
				<i-switch v-model="row.status" @on-change="change_status(row)" :true-value="1" :false-value="0">
				</i-switch>
			</template>
			<template slot="title" slot-scope="{row}">
				<Tooltip placement="left" :transfer="true" max-width="600px">
					<span class="table-tooltip">{{ row.title }}</span>
					<div slot="content" v-html="row.title"></div>
				</Tooltip>
			</template>
			<template slot="content" slot-scope="{row}">
				<Tooltip placement="left" :transfer="true" max-width="600px">
					<span class="table-tooltip" v-html="row.content"></span>
					<span slot="content" v-html="row.content"></span>
				</Tooltip>
			</template>
			<template slot-scope="{row}" slot="action">
				<Tooltip content="编辑" placement="top">
					<Button shape="circle" type="primary" @click="edit_version(row)"
						custom-icon="iconfont icon-edit"></Button>
				</Tooltip>
			</template>
		</Table>
		<div class="ser-page">
			<Page :total="total" :page-size="search.pageSize" :current="search.page" :page-size-opts="[15,30,50]"
				@on-change="set_current" @on-page-size-change="set_per" show-total show-sizer></Page>
		</div>
		<Modal v-model="save_info.show" title="保存版本" width="720">
			<Form :label-width="120" label-colon :rules="rulePrivateVersion" ref="formPrivateVersion"
				:model="save_info.form">
				<FormItem label="版本号" prop="version">
					<Input :disabled="save_info.form.id>0" v-model="save_info.form.version"
						placeholder="请输入版本号，例：1.0.0">{{ save_info.form.id }}</Input>
				</FormItem>
				<FormItem label="版本概要" prop="title">
					<Input v-model="save_info.form.title" maxlength="300" :rows="3" show-word-limit clearable
						type="textarea" placeholder="请输入版本版本概要"></Input>
				</FormItem>
				<FormItem label="开源版压缩包" prop="download_link" class="zip-private">
					<Button icon="ios-cloud-upload-outline" @click="upload_zip(1)"
						:loading="save_info.download_loading">上次开源版压缩包</Button>
					<div v-if="save_info.openSource[0]">开源版.zip</div>
					<Progress :percent="percent_download_num" v-if="percent_download_loading" />
				</FormItem>
				<FormItem label="加密版压缩包" prop="encryption_link" class="zip-private">
					<Button icon="ios-cloud-upload-outline" @click="upload_zip(0)"
						:loading="save_info.encryption_loading">上传加密版压缩包</Button>
					<div v-if="save_info.encryption[0]">加密开源版.zip</div>
					<Progress :percent="percent_encryption_num" v-if="percent_encryption_loading" />
				</FormItem>
				<FormItem label="版本日志" prop="content">
					<Editor ref="editorIntro" class="editor-text" :value="save_info.form.content"></Editor>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button type="text" @click="save_info.show = false">取消</Button>
				<Button type="primary" @click="save_submit">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import Editor from '../../../components/common/editor'
	import COS from 'cos-js-sdk-v5'
	const temp_save_info = {
		show: false,
		loading: false,
		download_loading: false,
		encryption_loading: false,
		form: {
			id: '',
			version: '',
			title: '',
			content: '',
			status: 1,
			download_link: '',
			encryption_link: '',
		},
		openSource: [],
		encryption: []
	};
	export default {
		name: 'private',
		components: {
			Editor,
		},
		data() {
			const validateDownloadLink = (rule, value, callback) => {
				if (value == '') {
					callback(new Error('请上传压缩开源版本'));
				}
				callback();
			};
			const validateEncryptionLink = (rule, value, callback) => {
				if (value == '') {
					callback(new Error('请上传压缩加密版本'));
				}
				callback();
			};
			return {
				ApiUrl: this.ApiUrl, // api地址
				loading: false,
				columns: [{
						title: 'ID',
						align: 'center',
						key: 'id',
						minWidth: 80,
						width: 80
					},
					{
						title: '版本号',
						align: 'center',
						key: 'version',
						minWidth: 120
					},
					{
						title: '上传时间',
						align: 'center',
						key: 'add_time',
						minWidth: 160
					},
					{
						title: '状态',
						align: 'center',
						slot: 'status',
						minWidth: 80
					},
					{
						title: '开源版下载地址',
						align: 'center',
						key: 'download_link',
						minWidth: 240
					},
					{
						title: '加密版下载地址',
						align: 'center',
						key: 'encryption_link',
						minWidth: 240
					},
					{
						title: '版本概要',
						align: 'center',
						slot: 'title',
						minWidth: 100
					},
					{
						title: '更新内容',
						align: 'center',
						slot: 'content',
						minWidth: 100
					},
					{
						title: '操作',
						slot: 'action',
						minWidth: 100
					}
				],
				data: [],
				total: 0,
				search: {
					pageSize: 15,
					page: 1
				},
				rulePrivateVersion: {
					version: [{
						required: true,
						message: '请输入版本号',
						trigger: 'blur'
					}],
					title: [{
						required: true,
						message: '请输入版本概要',
						trigger: 'blur'
					}],
					content: [{
						required: true,
						message: '请输入版本日志',
						trigger: 'blur'
					}],
					download_link: [{
						validator: validateDownloadLink,
						trigger: 'blur'
					}],
					encryption_link: [{
						validator: validateEncryptionLink,
						trigger: 'blur'
					}],
				},
				save_info: JSON.parse(JSON.stringify(temp_save_info)),
				percent_encryption_loading: false, //加密版加载显示
				percent_encryption_num: 0, //加密版进度
				percent_download_loading: false, //开源版加载显示
				percent_download_num: 0 //开源版进度
			}
		},
		created() {
			this.init()
		},
		mounted() {},
		methods: {
			reset() {
				this.save_info = JSON.parse(JSON.stringify(temp_save_info))
				this.$refs['formPrivateVersion'].resetFields()
				this.$refs.editorIntro.setContent('')
			},
			// 初始化数据
			init() {
				let _this = this
				this.requestApi('/adm/get_private_version', this.search).then((res) => {
					if (res.code == 200) {
						this.data = res.data.data
						this.total = res.data.total
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			// 切换页数
			set_current(page) {
				this.search.page = page;
				this.init();
			},
			// 切换条数
			set_per(limit) {
				this.search.page = 1;
				this.search.pageSize = limit;
				this.init();
			},
			// 添加版本
			add_version() {
				this.reset()
				this.save_info.show = true
			},
			// 编辑版本
			edit_version(row) {
				let item = JSON.parse(JSON.stringify(row))
				this.reset()
				this.save_info.show = true
				this.save_info.form = item
				if (item.download_link) {
					this.save_info.openSource = [{
						name: item.version + '.zip',
						url: item.download_link
					}]
				}
				if (item.encryption_link) {
					this.save_info.encryption = [{
						name: item.version + '.zip',
						url: item.encryption_link
					}]
				}
				this.$refs.editorIntro.setContent(item.content)
			},
			// 切换状态
			change_status(item) {
				let _this = this
				item.save_type = 1;
				_this.requestApi('/adm/edit_private_version', item).then((res) => {
					if (res.code == 200) {
						_this.alertSucc((item.status == 1 ? '启用' : '禁用') + '成功')
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			// 确定 提交新版本
			save_submit() {
				let _this = this
				// 赋值压缩包地址
				if (this.save_info.openSource.length) this.save_info.form.download_link = this.save_info.openSource[0].url
				if (this.save_info.encryption.length) this.save_info.form.encryption_link = this.save_info.encryption[0]
					.url
				_this.save_info.form.content = _this.$refs.editorIntro.getContent()
				if (this.save_info.form.download_link.length < 1) {
					_this.alertErr('开源压缩包必须上传')
					return false
				}
				if (this.save_info.form.encryption_link.length < 1) {
					_this.alertErr('加密压缩包必须上传')
					return false
				}

				if (_this.save_info.form.id) {
					_this.requestApi('/adm/edit_private_version', _this.save_info.form).then((res) => {
						if (res.code == 200) {
							_this.alertSucc('编辑成功')
							_this.save_info.show = false
							_this.init()
						} else {
							_this.alertErr(res.msg)
						}
					})
				} else {
					this.$refs['formPrivateVersion'].validate((valid) => {
						if (valid) {
							// 发送request请求
							this.requestApi('/adm/add_private_version', this.save_info.form).then((res) => {
								if (res.code == 200) {
									_this.alertSucc('新增成功')
									_this.save_info.show = false
									_this.init()
								} else {
									_this.alertErr(res.msg)
								}
							})
						}
					})
				}
			},
			//上传压缩包
			upload_zip(type) {
				var _this = this
				var input = document.createElement('input')
				input.setAttribute('type', 'file')
				input.setAttribute('accept', '.zip')
				input.onchange = function() {
					let files = this.files[0]
					_this.upload_progress(files, type)
				}
				input.click()
			},
			//调用腾讯云上传压缩包
			upload_progress(file, type) {
				if (type) {
					this.percent_download_num = 0
					this.percent_download_loading = true
					this.save_info.download_loading = true
				} else {
					this.percent_encryption_num = 0
					this.percent_encryption_loading = true
					this.save_info.encryption_loading = true
				}
				let _this = this
				this.putObject(file, type).then(
					(res) => {
						let url = res.pic_url
						let last = url.lastIndexOf('/')
						let name = url.substring(last + 1, url.length)
						if (type) {
							this.percent_download_loading = false
							this.save_info.download_loading = false
							this.save_info.openSource = [{
								name: name,
								url: 'https://' + url
							}]
						} else {
							this.percent_encryption_loading = false
							this.save_info.encryption_loading = false
							this.save_info.encryption = [{
								name: name,
								url: 'https://' + url
							}]
						}
					}
				)
			},
			//腾讯云存储操作
			putObject(fileObject, type) {
				let cos = new COS({
					SecretId: 'AKIDNoInIiR5e7Wp2BILKF7woeSIC5udGoIh',
					SecretKey: 'OfqJh0CINORVafi0HVgpuxOpFd0JZp4J'
				})
				let _this = this
				return new Promise((resolve) => {
					cos.putObject({
						Bucket: 'xunliao-1-1257941115',
						Region: 'ap-nanjing',
						Key: 'store_id_13/' + Date
							.parse(new Date()),
						Body: fileObject,
						onProgress: function(progressData) {
							let percent = progressData.percent * 100 | 0;
							if (type) {
								_this.percent_download_num = percent

							} else {
								_this.percent_encryption_num = percent
							}
						}
					}, function(err, data) {
						if (err) return _this.alertErr(err)
						resolve({
							pic_url: data.Location
						})
					})
				})
			}
		}
	}
</script>

<style scoped>
	.body {
		background-color: #fff;
		padding: 16px;
	}

	.table-tooltip {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
	}

	.ser-page {
		padding-bottom: 0;
		text-align: right;
	}

	.top-search {
		padding: 0 16px 16px;
	}

	.editor-text {
		padding-top: 10px;
	}

	.zip-private {
		margin-top: 30px;
	}
</style>