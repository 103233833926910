<template>
  <div class="tinymce-box">
    <editor api-key="g6tycx2ur60d0fjv6d65j13cflhz30y0888xn9s3vky0yew1" :key="tinymceFlag" :init="init" v-model="htmlvalue"></editor>
    <Spin fix v-if="video_show"></Spin>
  </div>
</template>

<script>
  var tinymce_this;
  import Editor from '@tinymce/tinymce-vue'
  export default {
    components: {
      Editor
    },
    name: 'Tinymce',
    props: {
      height: {
        type: Number,
        default: 500
      },
      // 默认的富文本内容
      value: {
        type: String,
        default: ''
      },
      // 禁用
      disabled: {
        type: Boolean,
        default: false
      },
      //插件列表
      plugins: {
        type: Array,
        default: () => {
          return [
            'advlist autolink lists link imagetools charmap searchreplace visualblocks code insertdatetime media table paste code wordcount hr emoticons image axupimgs',
          ]
        }
      },
      //工具栏列表
      toolbar: {
        type: Array,
        default: () => {
          return [
            'undo redo restoredraft | cut copy paste pastetext | bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | table image axupimgs media charmap emoticons insertdatetime code fullscreen',
            'formatselect fontselect fontsizeselect lineheight forecolor backcolor | bullist numlist blockquote subscript superscript hr removeformat'
          ]
        }
      }
    },
    data() {
      return {
        video_show: false,
        htmlvalue: this.value,
        ApiUrl: this.ApiUrl,
        ImgUrl: this.ImgUrl,
        mvUrl: null,
        uploaded: false,
        tinymceFlag: 1,
        init: {
          language: 'zh_CN',
          height: this.height,
          relative_urls : false,
          remove_script_host : false,
          document_base_url : this.ImgUrl,
          menubar: false,
          theme: 'silver',
          media_live_embeds: true,
          //file_picker_callback: 'media',
          plugins: this.plugins,
          fontSize: 12,
          toolbar: this.toolbar,
          statusbar: false,
          branding: false,
          media_url_resolver: function(data, resolve) {
            try {
              //let videoUri = encodeURI(data.url);
              let embedHtml = `<p>
                              <video src=${data.url} controls="controls" controlslist="nodownload">
                              </video>
                          </p>
                          <p style="text-align: left;"></p>`;
              resolve({
                html: embedHtml
              });
            } catch (e) {
              resolve({
                html: ""
              });
            }
          },
          file_picker_callback: function(cb, value, meta) {
            if (meta.filetype === 'media') {
              let input = document.createElement('input')
              input.setAttribute('type', 'file')
              input.setAttribute('accept', '.mp4')
              input.onchange = function() {
                let file = this.files[0]
                tinymce_this.uploadMv(file)
                if (tinymce_this.uploaded) {
                  cb(tinymce_this.mvUrl)
                } else {
                  let time = setInterval(() => {
                    if (tinymce_this.mvUrl != null) {
                      cb(tinymce_this.mvUrl)
                      clearInterval(time)
                    }
                  }, 1000)
                }
              }
              input.click()
            }
          },
          images_upload_handler: function(blobInfo, succFun, failFun) {
            var file = blobInfo.blob()
            var formData = new FormData();
            formData.append('file', file);
            formData.append('dir', 'tinymce_upload');
            formData.append('token', tinymce_this.getToken())
            tinymce_this.requestApi('/adm/upload_object', formData, {
              'content-Type': 'multipart/form-data'
            },true).then(function(res) {
              if (res.status == 1) {
                if(res.pic_url.indexOf('upload') != -1) {
                 succFun(tinymce_this.ImgUrl + res.pic_url)
                }else {
                  succFun('https://' + res.pic_url)
                }
              } else {
                failFun(res.msg);
              }
            })
          }
        }
      }
    },
    mounted() {
      tinymce_this = this;
      this.tinymceFlag++;
    },
    activated() {
      this.tinymceFlag++;
    },
    methods: {
      //获取富文本内容
      getContent: function() {
        return this.htmlvalue;
      },
      //设置富文本内容
      setContent: function(value) {
        this.htmlvalue = value;
      },
      //上传视频
      uploadMv(file) {
        let content = file
        tinymce_this.video_show = true
        let formData = new FormData()
        formData.append('file', content)
        tinymce_this.requestApi('/adm/upload_object', formData, {
          'content-Type': 'multipart/form-data'
        },true).then(
          (res) => {
            if (res.status == 1) {
              tinymce_this.video_show = false
              tinymce_this.mvUrl = 'https://' + res.pic_url
              tinymce_this.uploaded = true
            } else {
              tinymce_this.$Message.error(res.msg)
            }
          }
        ).catch(err => tinymce_this.$Message.error(err))
      }
    }
  }
</script>
<style scoped>

</style>
